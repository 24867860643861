import React, { useEffect } from "react";

const Load = () => {
  const growContainer = React.useRef(null);

  useEffect(() => {
    grow();
  }, []);

  function grow() {
    let i = 0;
    const grows = [...growContainer.current.children];
    setInterval(() => {
      if (i === 3) {
        i = 0;
        grows[i].style.opacity = "0.5";
        grows[i].style.height = "14px";
        grows[i].style.width = "14px";
        grows[i + 1].style.opacity = "0.5";
        grows[i + 2].style.opacity = "0.5";
      } else {
        grows[i].style.opacity = "0.2";
        i++;
      }
    }, 200);
  }

  const styles = {
    grow: {
      height: "12px",
      width: "12px",
      opacity: "0.5",
      backgroundColor: "#fff",
      margin: "0 0.3rem",
      borderRadius: "50%",
    },
  };

  return (
    <div
      ref={growContainer}
      style={{ display: "flex", marginRight: "-10px", paddingTop: "3rem" }}
    >
      <div style={styles.grow}></div>
      <div style={styles.grow}></div>
      <div style={styles.grow}></div>
    </div>
  );
};

export { Load };

import React, { useEffect, useRef, useState } from "react";
import branding from "../../branding/branding.config.json";
import { UAParser } from 'ua-parser-js';
import { useAuth } from "../../provider/auth";
import IconButton from "@mui/material/IconButton";
import Player from "@vimeo/player";
import { toggleFullscreen } from "./helpers";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PauseIcon from "@mui/icons-material/Pause";
import Backdrop from "@mui/material/Backdrop";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import CircularProgress from "@mui/material/CircularProgress";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Forward10Icon from "@mui/icons-material/Forward10";
import Slider from "@mui/material/Slider";
import Replay10 from "@mui/icons-material/Replay10";
let player = null;
let timer;
let {browser} = UAParser(window.navigator.userAgent);

/* let testurl =
  "https://tipconsola.inatrans.cl/?config=iframe&video_id=325654952&h=400d92f416";
 */
function VPlayer() {
  const [hovered, setHovered] = useState(false);
  const [backdrop, setBackdrop] = useState(true);
  const [quality] = useState("auto");
  /* const [anchorEl, ] = useState(null); */
  const [videoTitle, setVideoTitle] = useState("");
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [clockState, setClock] = useState(null);
  const [range, setRange] = useState(null);
  const iframe = useRef(null);
  const { auth } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  /* const open = Boolean(anchorEl); */

  const generateDurationTimer = (sc) => {
    let h = Math.floor(sc / 60 / 60);
    let m = Math.floor(sc / 60 - h * 60);
    let s = Math.floor(sc - m * 60 - h * 60 * 60);
    return `${h < 1 ? "" : h < 10 ? "0" + h + ":" : h + ":"}${
      m < 10 ? "0" + m : m
    }:${s < 10 ? "0" + s : s}`;
  };

  const generateTimer = async (data, sc) => {
    let isMobile = window.innerWidth < 678;
    let seconds = Math.floor(data.seconds);
    let h = Math.floor(seconds / 60 / 60);
    let m = Math.floor(seconds / 60 - h * 60);
    let s = Math.floor(seconds - m * 60 - h * 60 * 60);
    let timer = `${h < 1 ? "00:" : h < 10 ? "0" + h + ":" : h + ":"}${
      m < 10 ? "0" + m : m
    }:${s < 10 ? "0" + s : s}`;
    let durationTimer = generateDurationTimer(sc);
    let clock = isMobile ? `${timer}` : `${timer} - ${durationTimer}`;
    setClock(clock);
    setRange(seconds);
    return clock;
  };

  useEffect(() => {
    let isMobile = window.innerWidth < 678;
    if (auth.cookies?.video_id && !player) {
/*       setTimeout(() => {
        setBackdrop(false);
      }, 2000); */

      player = new Player(iframe.current);
      browser.name.toLowerCase().includes('safari') && player.setMuted(true)
      browser.name.toLowerCase().includes('safari') && setModalOpen(true)

      player.getVideoTitle().then((title) => {
        const video_title = title.split(".");
        setVideoTitle(video_title[0]);
      });

      player.on("playing", () => {
        setPlaying(true);
        setBackdrop(false);
      });

      player.getDuration().then((res) => {
        let sc = Math.floor(res);
        let clock = generateDurationTimer(sc);
        let clockTextContent = isMobile ? `${clock}` : `00:00:00 - ${clock}`;
        setClock(clockTextContent);
        setDuration(sc);
        player.on("timeupdate", (data) => {
          generateTimer(data, sc);
        });
      });
    }

   return () => setModalOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controls = {
    fullScreen: function () {
      toggleFullscreen();
    },
    play: function () {
      setBackdrop(true);
      player.play();
    },
    pause: function () {
      player.pause();
      setPlaying(false);
    },
    handleSeek: function (e, newValue) {
      player.pause();
      setRange(newValue);
    },
    seek: function (e, newValue) {
      setBackdrop(true);
      player.setCurrentTime(newValue).then(() => {
        setBackdrop(false);
        player.play();
      });
    },
    closeBackdrop: function () {
      setBackdrop(false);
    },
    forward10: function () {
      const currTime = JSON.parse(range);
      if (currTime > duration - 10) {
        return;
      }
      const forward10 = currTime + 10;
      setBackdrop(true);
      controls.pause();
      player.setCurrentTime(forward10).then(() => {
        setRange(forward10);
        setBackdrop(false);
        controls.play();
      });
    },
    replay10: function () {
      const currTime = JSON.parse(range);
      if (currTime < 10) {
        return;
      }
      const replay10 = currTime - 10;
      setBackdrop(true);
      controls.pause();
      player.setCurrentTime(replay10).then(() => {
        setBackdrop(false);
        controls.play();
      });
    },
  };

  const handleMovement = () => {
    clearTimeout(timer);
    setHovered(true);
    timer = setTimeout(() => {
      setHovered(false);
    }, 3000);
  };

  return (
    <>
      <div id="video-wrap">
        <PlayerControls
          controls={controls}
          auth={auth}
          hovered={hovered}
          setHovered={setHovered}
          handleMovement={handleMovement}
          range={range}
          playing={playing}
          videoTitle={videoTitle}
          duration={duration}
          player={player}
          clockState={clockState}
          backdrop={backdrop}
          handleSeek={controls.handleSeek}
          setModalOpen={setModalOpen}
        />
        {browser.name.toLowerCase().includes('safari') && modalOpen && !backdrop &&(
           <div style={stylesModal.overlay}>
           <div style={stylesModal.modal}>
             <h2 style={{color:'#fff'}}>Ajusta el Volumen para Reproducir el Video</h2>
             <p>
               Para disfrutar del video, asegúrate de que el volumen de tu dispositivo esté activado.
             </p>
           </div>
         </div>
          )}
        <VimeoPlayer iframe={iframe} quality={quality} auth={auth} />
        <BackDrop backdrop={backdrop} handleBackdrop={controls.closeBackdrop} />
      </div>
    </>
  );
}

const PlayerControls = ({
  videoTitle,
  clockState,
  playing,
  controls,
  auth,
  player,
  hovered,
  handleMovement,
  backdrop,
  duration,
  range,
  setModalOpen
}) => {
  const [volumeActive, setVolumeActive] = useState(false);
  const [volume, setVolume] = useState(null);

  React.useEffect(() => {
    if (player) {
      player.getVolume().then((res) => {
        console.log('volume')
        console.log(res)
        setVolume(res);
      });
    }
  }, [player]);

  const handleVolume = (e, newValue) => {
    player.setVolume(newValue).then(() => {
      console.log('setVolume new value: ' + newValue)
      setModalOpen(false)
      setVolume(newValue);
    });
  };

  const styles = {
    controls: {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: "100000",
    },
    top: {
      position: "absolute",
      padding: "1rem 2rem",
      top: "0",
      left: "0",
      right: "0",
    },
    center: {
      WebkitTransform: "translate(-50%,-50%)",
      transform: "translate(-50%,-50%)",
      position: "absolute",
      top: "50%",
      left: "50%",
    },
    bottom: {
      position: "absolute",
      bottom: "0",
      left: "0",
      right: "0",
      padding: "0.5rem 0",
    },
    range: {
      display: "flex",
      margin: "0 auto",
      justifyContent: "center",
      position: "absolute",
      bottom: 60,
      left: 0,
      right: 0,
      opacity: hovered ? "0.9" : "0",
    },
    controlsBackground: {
      position: "absolute",
      bottom: 0,
      height: 70,
      left: 0,
      right: 0,
      background: hovered ? "black" : "transparent",
      transition: "background 0.5s",
    },
    clock_style: {
      fontWeight: 300,
    },
  };

  return (
    <div style={styles.controls} onMouseMove={handleMovement}>
      <div style={styles.top}>
        {auth.cookies.brand && (
          <a
            href={`${branding[auth.cookies.brand].streaming_url}${
              auth.cookies.url
            }`}
          >
            <ControlButton
              hovered={hovered}
              handleMovement={handleMovement}
              size="text"
            >
              <p>{videoTitle}</p>
            </ControlButton>
          </a>
        )}
      </div>
      <div style={styles.controlsBackground}>
        <div style={styles.range}>
          <Slider
            sx={{ transition: "0.5s", color: "red" }}
            onChange={controls.handleSeek}
            onChangeCommitted={controls.seek}
            value={range}
            type="range" /* className="form-range" */
            min={0}
            max={JSON.parse(duration)}
            step={1}
          />
        </div>
        <ControlButton hovered={hovered} position="left-bottom">
          <div style={{ opacity: "0.7" }}>
            <h5 style={styles.clock_style}>{clockState && clockState}</h5>
          </div>
        </ControlButton>
        <ControlButton
          hovered={hovered}
          size="icon"
          position="center-left"
          handleClick={controls.replay10}
          disabled={backdrop ? true : false}
        >
          <Replay10 />
        </ControlButton>
        <ControlButton
          hovered={hovered}
          size="icon"
          position="center"
          handleClick={playing ? controls.pause : controls.play}
        >
          {playing ? <PauseIcon /> : <PlayCircleFilledIcon />}
        </ControlButton>
        <ControlButton
          hovered={hovered}
          size="icon"
          position="center-right"
          handleClick={controls.forward10}
          disabled={backdrop ? true : false}
        >
          <Forward10Icon />
        </ControlButton>
        <ControlButton
          hovered={hovered}
          position="volume"
          volumeActive={volumeActive}
          handleClick={() => setVolumeActive(!volumeActive)}
          size="volume"
        >
          {volume > 0.5 ? (
            <VolumeUp sx={{ marginRight: 1 }} />
          ) : volume < 0.1 ? (
            <VolumeOffIcon sx={{ marginRight: 1 }} />
          ) : (
            <VolumeDown sx={{ marginRight: 1 }} />
          )}
          <Slider
            sx={{
              marginRight: 1,
              display: `${volumeActive ? "block" : "none"}`,
              transition: "0.5s",
            }}
            aria-label="Volume"
            onChange={handleVolume}
            value={volume}
            step={0.05}
            min={0}
            max={1}
          />
        </ControlButton>
        <ControlButton
          hovered={hovered}
          handleClick={controls.fullScreen}
          position="right-bottom"
          size="icon"
        >
          <FullscreenIcon />
        </ControlButton>
      </div>
    </div>
  );
};

const ControlButton = ({
  children,
  hovered,
  size,
  handleClick,
  mr,
  position,
  volumeActive,
  disabled,
}) => {
  const [active, setActive] = useState(false);
  const isMediumScreen = window.innerWidth < 900 && window.innerWidth > 678;
  const isMobile = window.innerWidth < 678;

  const right_bottom = {
    padding: "0.2rem 0",
    marginRight: mr ? mr : "",
    position: "absolute",
    bottom: 10,
    right: 10,
  };
  const volume = {
    width: volumeActive ? 200 : 50,
    padding: "0.2rem 0",
    marginRight: volumeActive ? "0.5rem" : "",
    position: "absolute",
    bottom: 10,
    right: 50,
    transition: "0.4s",
    display: isMobile ? "none" : "",
  };
  const left_bottom = {
    padding: "0.2rem 0",
    marginRight: mr ? mr : "",
    position: "absolute",
    bottom: 15,
    left: 10,
  };
  const center = {
    padding: "0.2rem 0",
    marginLeft: -2,
    position: "absolute",
    bottom: 10,
    left: "50%",
  };
  const center_right = {
    padding: "0.2rem 0",
    marginLeft: -2,
    position: "absolute",
    bottom: 10,
    left: isMediumScreen ? "57%" : "55%",
    display: isMobile ? "none" : "",
  };
  const center_left = {
    padding: "0.2rem 0",
    marginLeft: -2,
    position: "absolute",
    bottom: 10,
    left: isMediumScreen ? "43%" : "45%",
    display: isMobile ? "none" : "",
  };
  const control_styles = {
    fontSize:
      size === "text"
        ? active
          ? "2.5rem"
          : "2rem"
        : size === "icon"
        ? active
          ? "3rem"
          : "2.5rem"
        : size === "main"
        ? active
          ? "6rem"
          : "4rem"
        : size === "volume"
        ? "2.5rem"
        : "1rem",
    color: hovered ? "#fff" : "transparent",
    transition: "0.5s",
    opacity: active ? "1" : "0.6",
  };
  const icon_css =
    position === "left-bottom"
      ? left_bottom
      : position === "right-bottom"
      ? right_bottom
      : position === "center"
      ? center
      : position === "volume"
      ? volume
      : position === "center-right"
      ? center_right
      : position === "center-left"
      ? center_left
      : null;

  const handleActive = () => {
    setActive(!active);
  };

  return (
    <IconButton sx={icon_css} onClick={handleClick} disabled={disabled}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onMouseEnter: handleActive,
          onMouseLeave: handleActive,
          style: control_styles,
        });
      })}
    </IconButton>
  );
};

const VimeoPlayer = ({ iframe, quality, auth }) => {
  useEffect(() => {
    console.log(browser.name.toLowerCase().includes('safari'))
  
  }, [])
  
  const styles = {
    video_container: {
      maxHeight: "100vh",
      height: "100vh",
      width: "100vw",
      background: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    iframe: {
      maxHeight: "100vh",
      width: "100%",
    },
  };

  /* src={`https://player.vimeo.com/video/${auth.cookies.video_id}?h=&controls=false&quality=${quality}&autoplay=1&muted=1`} */

  return (
    <div style={styles.video_container}>
      {
        browser.name.toLowerCase().includes('safari') ? (
        <iframe
          title="vimeo"
          ref={iframe}
          style={styles.iframe}
          src={`https://player.vimeo.com/video/${auth.cookies.video_id}?h=&controls=false&quality=${quality}&autoplay=1&muted=1`}
          controls={false}
          width={1200}
          height={900}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          allow="autoplay"
        ></iframe>) : (
          <iframe
            title="vimeo"
            ref={iframe}
            style={styles.iframe}
            src={`https://player.vimeo.com/video/${auth.cookies.video_id}?h=${auth.cookies.h}&controls=false&quality=${quality}&autoplay=1`}
            controls={false}
            width={1200}
            height={900}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          ></iframe>
        )
      }

    </div>
  );
};

const BackDrop = ({ backdrop, handleBackdrop }) => (
  <Backdrop
    sx={{
      background: "black",
      opacity: "0.1",
      color: "#fff",
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}
    open={backdrop}
    onClick={handleBackdrop}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export { VPlayer as Player };


const stylesModal = {
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    opacity:0.7,
    color:'#000',
    display: 'flex',
    justifyContent:'flex-end',
    alignItems: 'end',
  },
  modal: {
    opacity:0.95,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    padding: '20px',
    marginBottom:'60px',
    fontSize:'12px',
    borderRadius: '8px',
    maxWidth: '400px',
    textAlign: 'center',
  },
};

import React from "react";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { Load } from "./Loading";
import loader from "./logo.png";
import { Spinner } from "./spinkit";

export function Loader({ appActions }) {
  const [opacity, setOpacity] = React.useState(0);
  useEffect(() => {
    setInterval(() => {
      setOpacity((prev) => prev + 0.02);
    }, 50);
    setTimeout(() => {
      appActions({
        type: "loading",
        payload: { loading: false },
      });
    }, 1900);
    return () => setOpacity(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const css = {
    minHeight: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  };

  const styles = {
    logo: {
      opacity: `${opacity}`,
    },
  };

  return createPortal(
    <main style={css}>
      <img style={styles.logo} alt="load" src={loader} width={"300px"} />
      <Load />
    </main>,
    document.getElementById("loader")
  );
}

export function LoaderV2({ appActions }) {
  const [opacity, setOpacity] = React.useState(0);
  useEffect(() => {
    setInterval(() => {
      setOpacity((prev) => prev + 0.015);
    }, 50);

    setTimeout(() => {
      appActions({
        type: "loading",
        payload: { loading: false },
      });
    }, 3000);
    return () => setOpacity(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const css = {
    minHeight: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    opacity: `${opacity}`,
  };

  const styles = {
    logo: {},
  };

  return createPortal(
    <main style={css}>
      <img style={styles.logo} alt="load" src={loader} width={"180px"} />
      <Spinner />
    </main>,
    document.getElementById("loader")
  );
}

import React from "react";
import { useModal } from "../../provider/modal";
import { useApp } from "../../provider/app";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { useConfig } from "../../provider/config";
import { Box } from "@mui/system";
import { Button, Paper } from "@mui/material";
import { Load } from "../loader/Loading";

const Quest = ({ player, handleStart }) => {
  const { app } = useApp();
  const [loading, setLoading] = React.useState(false);
  const { modal_state, modal_dispatch } = useModal();
  const { answers, modal_type, modal_title, correct_answer } = modal_state;
  const { configActions, set_user_response, user_response } = useConfig();
  const { onUserResponse } = configActions;
  const isExample = app.v_desc.title === "Actividad de ensayo" ? true : false;
  const isMobile = window.innerWidth < 900;

  const handleChange = (e) => {
    set_user_response(e.target.value);
  };

  const handleResponse = () => {
    setLoading(true);
    let type = isExample ? "intro" : "box";
    let idx = app.questions.findIndex((q) => q.title === modal_title);
    console.log(idx);
    console.log(type)
    console.log(app.questions)
    if (idx === app.questions.length - 1) {
      console.log('first')
      onUserResponse(user_response, modal_title, correct_answer, type);
      setTimeout(() => {
        setLoading(false);
      }, 100);
      player.play();
    } else {
      console.log('second')
      onUserResponse(user_response, modal_title, correct_answer, type);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleIntro = () => {
    modal_dispatch({ type: "none" });
    console.log('dispatch handlestart')
    return handleStart();
  };

  const ModalBody = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minWidth: 350,
            maxWidth: 950,
            backgroundColor: "#212529",
          },
          margin: "0 auto",
        }}
      >
        {modal_type === "intro" ? (
          <Paper elevation={3} style={{ padding: "1rem 2rem" }}>
            {app.config === "quest" && <QuestIntro />}
            {app.config === "click" && <ClickIntro />}
            <Button
              sx={{ margin: "1rem 42.5%" }}
              variant="contained"
              color="primary"
              onClick={handleIntro}
            >
              Comenzar
            </Button>
          </Paper>
        ) : (
          ""
        )}
      </Box>
    );
  };

  const QuestIntro = () => (
    <>
      <h4
        className="my-4"
        style={{ color: "#fff", fontWeight: "500", textAlign: "center" }}
      >
        Bienvenido a la evaluación de Tiempo de reacción y tiempo de percepción e identificación del riesgo
      </h4>
      <br />
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Usted debe ver una serie de videos e identificar y reaccionar cuando crea que existe una situación de riesgo.
      </p>
      <span className="font-bold text-white">
        Instrucciones:
      </span>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        El siguiente ejercicio mide la capacidad de identificar y reaccionar ante una situación de riesgo en la conducción.
        Usted debe realizar 2 ejercicios los cuales se dividen en:
      </p>
      <div className="px-4">
        <span className="font-bold text-white">
          Ejercicio 1
        </span>
        <p
          style={{
            color: "#fff",
            fontWeight: "300",
            textAlign: "left",
            marginBottom: "1rem",
          }}
        >
          Usted tendrá 5 minutos para ver 5 videos en los cuales debe hacer clic rápidamente cuando crea que aparezca un riesgo. El objetivo del ejercicio, es evaluar su tiempo de reacción ante una situación de peligro en la conducción.
          Importante: Hacer clic no representa la acción de frenar, representa la identificación de un posible riesgo durante el video, desde su punto de vista.
        </p>
        <span className="font-bold text-white">

          Ejercicio 2
        </span>
        <p
          style={{
            color: "#fff",
            fontWeight: "300",
            textAlign: "left",
            marginBottom: "1rem",
          }}
        >

          Usted debe visualizar 5 videos que se reproducirán de manera automática en la cual debe identificar una posible situación de riesgo. La pantalla tendrá 4 divisiones y usted tendrá que elegir en cuál cuadrante se encuentra el riesgo, seleccionando el número del cuadrante en las alternativas que se le muestren.

          A continuación, usted deberá responder 2 preguntas relacionadas con la actitud del conductor y la consecuencia del riesgo. Usted tendrá 10 minutos para realizar este ejercicio, pasado este tiempo su evaluación será enviada automáticamente.
        </p>
      </div>
      <span className="font-bold text-white">
        Atención:
      </span>
      <ul
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
          listStyleType: "none",
        }}
      >
        <li>
          Recomendamos aumentar el brillo de su pantalla, para una mejor experiencia.
        </li>
        <li>Antes de comenzar, lea cuidadosamente las instrucciones de cada evaluación.
        </li>
        <li>Si abandona, o recarga la página, las preguntas se reordenarán y se perderá el progreso.
        </li>
      </ul>
      {/*  <h4
        className="my-4"
        style={{ color: "#fff", fontWeight: "500", textAlign: "center" }}
      >
        Bienvenido a la evaluación de predicción de riesgos en la conducción
      </h4>
      <br />
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        A continuación, se le presentarán 9 videos en los que usted tendrá que
        identificar una situación de riesgo, mientras se conduce. Usted tendrá
        15 minutos para realizar toda la evaluación, y deberá responder 3
        preguntas en cada video. Si sobrepasa ese tiempo, su respuesta se
        considerará errónea. Tiene que responder rápidamente como si estuviera
        en una situación real.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Deberá completar los 9 videos para dar por finalizada la evaluación. El
        tiempo se mostrará en un cronómetro en la parte superior derecha. En
        cada video se mostrará una situación de tránsito en la que usted figura
        como el conductor.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        1.- En la primera se dividirá la pantalla en cuatro y usted tendrá que
        responder en qué cuadrante se encuentra el riesgo. Tiene que responder
        el número del cuadrante en las opciones que se le mostrarán.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        2.- Se le indicarán varias acciones y usted tendrá que elegir la que
        usted tomaría en esa situación.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        3.- Se le van a indicar varias situaciones que pueden describir lo que
        sucederá luego de la detención del video y usted debe elegir lo que cree
        que pasará.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Recuerde que debe responder en forma rápida, igual como si estuviera en
        la situación real. Si supera el tiempo las respuestas se enviarán de
        forma automática y se contarán como erróneas. Contará con 2 ejercicios
        de ensayo. Luego de éstos, comenzará el ensayo y a contar el tiempo en
        el cronometro en la parte superior.
      </p> */}
    </>
  );
  const ClickIntro = () => (
    <>
      <h4
        className="my-4"
        style={{ color: "#fff", fontWeight: "500", textAlign: "center" }}
      >
        Bienvenido a la evaluación de tiempo de reacción en la conducción
      </h4>
      <br />
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        A continuación, se le presentarán 9 videos en los que usted tendrá que
        identificar una posible situación de riesgo, haciendo click en la
        pantalla. tendrá 3 segundos para identificar el riesgo en cada video.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Usted tendrá 7 minutos para realizar la evaluación. Si sobrepasa ese
        tiempo, su respuesta se considerará errónea.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Tiene que responder rápidamente como si estuviera en una situación real.
        Una vez transcurrido el tiempo, se desplegará el siguiente video y así
        sucesivamente hasta completar los 9. El tiempo se mostrará en un
        cronómetro en la parte superior derecha. En cada video se mostrará una
        situación de tránsito en la que usted figura como el conductor.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Recuerde que debe responder en forma rápida, igual como si estuviera en
        la situación real. Si supera el tiempo tendrá una respuesta errónea.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Contará con 2 ejercicios de ensayo. Luego de éstos, comenzará la ensayo
        y a contar el tiempo en el cronometro en la parte superior.
      </p>
    </>
  );

  return (
    <div className="card-body p-4 px-4">
      <div
        style={{
          background: modal_type === "intro" ? "transparent" : "#212529",
          width: isMobile ? "100%" : "60%",
          minHeight: "50vh",
        }}
        className="m-auto p-3"
      >
        {!loading && modal_type !== "intro" && (
          <div className="d-flex justify-content-center align-items-center mb-4 mt-3">
            <h4
              style={{
                margin: 0,
                fontWeight: "400",
                width: "auto",
                textJustify: "justify",
              }}
              className="card-title text-white mx-auto"
            >
              {modal_state.modal_title}
            </h4>
          </div>
        )}
        {modal_state.modal_title.includes("cuadrante") && (
          <div
            style={{
              marginBottom: 10,
              width: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              width={"70%"}
              alt="cuadrante"
              src={require(`../../frames/${app.v.video_id}.png`)}
            />
          </div>
        )}
        <div
          className="d-flex px-0"
          style={{ width: "71.8%", margin: "0 auto", verticalAlign: "center" }}
        >
          <RadioGroup
            aria-label="gender"
            defaultValue="female"
            name="radio-buttons-group"
          >
            {!loading && modal_type === "box"
              ? answers &&
              answers.map((answer, idx) => {
                return (
                  <FormControlLabel
                    sx={{ color: "#fff", margin: "0.1rem 0" }}
                    key={idx}
                    value={answer}
                    control={<Radio />}
                    label={answer}
                    onChange={handleChange}
                  />
                );
              })
              : ""}
          </RadioGroup>
        </div>

        {!loading && modal_type === "box" ? (
          <Box
            sx={{
              margin: "1rem 2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ margin: "1rem" }}
              variant="contained"
              color="primary"
              onClick={handleResponse}
              disabled={!user_response ? true : false}
            >
              Confirmar
            </Button>
          </Box>
        ) : (
          ""
        )}
        {modal_type === "intro" && !loading && <ModalBody />}
        {loading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10% 0",
            }}
          >
            <Load />
          </div>
        )}
      </div>
    </div>
  );
};

export { Quest };

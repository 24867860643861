import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import PrintIcon from "@mui/icons-material/Print";
import { CSVLink } from "react-csv";
import axios from "axios";
import { useAuth } from "../../provider/auth";
import { Load } from "../../components/loader/Loading";
import zIndex from "@mui/material/styles/zIndex";
import { Details, Visibility } from "@mui/icons-material";
import { InatransNavigation } from "../../provider/auth/navigator";
import { InatransLogo } from "../../components/navigation";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import questions from "./data.json";

const { questions: psycho_quiz } = questions;

let MODULES = {
  ina0: "Tiempo de reacción y tiempo de percepción e identificación del riesgo",//"Control de riesgos",
  ina1: "Identificación de riesgos",//"Evaluación de riesgos",
  ina2: "Evaluación psicológica",
  ina4: "Actitud y conducta"
};

function Admin() {
  const { config } = useAuth();
  const fetch_url = "https://tipconsola.iapp.cl/api/totalresults";
  const [rows, setRows] = React.useState([]);
  const [rowsCSV, setRowsCSV] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [detail, openDetail] = useState(false);
  const [detailResults, setDetailResults] = useState();
  //let resultado = "{\"module_name\":\"ina1\",\"totalanswers\":20,\"correct\":0,\"incorrect\":20,\"results_detail\":[],\"results\":\"No Cumple\",\"elapsedSeconds\":360}"
  //console.log(JSON.parse(resultado))
  class csvInjection {
    constructor(args) {
      this.tip_user_id = args.user_id;
      this.tip_username = args.user_name;
      this.tip_numcorrect = args.num_correct;
      this.tip_numquestion = args.num_questions;
      this.tip_score = args.score;
      this.tip_maxscore = args.max_score;
      this.tip_testname = args.url_name === "quest" ? "Control de riesgo" : "";
    }
  }

  function _parse(str) {
    return JSON.parse(JSON.parse(str));
  }

  function parseAndFormat(data) {
    // console.log({ data });
    let output = [];
    /*     let users = data.map((module) => ({ userid: module.user_id, modules: [] }));
     */
    for (let module of data) {
      // console.log({ module });
      let newmodule = { ...module };
      let parsedResults = _parse(newmodule.module_results);
      let parsedDetails = _parse(newmodule.results);
      newmodule.module_results = parsedResults;
      newmodule.results = parsedDetails;
      output = [...output, newmodule];
    }
    return output;
  }
  function parseCsv(data) {
    let output = [];
    /*     let users = data.map((module) => ({ userid: module.user_id, modules: [] }));
     */
    for (let module of data) {
      let newmodule = { ...module };
      newmodule.course_name = decodeURIComponent(newmodule.course_name);
      newmodule.user_name = decodeURIComponent(newmodule.user_name);
      output = [...output, newmodule];
    }
    return output;
  }

  // Función para escapar comillas dobles y envolver valores con comillas dobles
function escapeCsvValue(value) {
    // Escapar comillas dobles dentro del valor
    value = value.replace(/"/g, '""');

    if (value.includes(',') || value.includes('\n')) {
      value = `"${value}"`;
    }
    return `"${value}"`;
}

  React.useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(
          `https://tipconsola.iapp.cl/api/tracking/totalresults/inatrans/youtube/${config.course_id}`
        );
        let usersData = parseAndFormat(data);
        let csvData = parseCsv(data);
        console.log(data)
        console.log(usersData)
        console.log(csvData)
        // add to the csvData the totalAnswers, correct, incorrect and results of the usersData
        csvData = csvData.map((user) => {
          let userResults = usersData.find((u) => u.user_id === user.user_id && u.id === user.id);
          console.log(userResults)
          user.num_correct = userResults.module_results.correct;
          user.num_questions = userResults.module_results.totalanswers;
          user.score = userResults.module_results.results;
          user.total_answers = userResults.module_results.totalanswers;
          user.module_name = userResults.module_results.module_name;
          // modificar results y module_results porque tienen comas y hay que escaparlas
          user.results = escapeCsvValue(user.results)
          user.module_results = escapeCsvValue(user.module_results)
          console.log(user)
          return user
        });

        setRowsCSV(csvData);
        setRows(usersData);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetail = (module) => {
    openDetail(true);
    setDetailResults(module);
    console.log({ module });

    if (module.module_results.module_name === "ina0") {
      let entries = Object.entries(module.results);
      entries.sort(function (a, b) {
        return (
          parseInt(a[0].split(" - ")[0], 10) -
          parseInt(b[0].split(" - ")[0], 10)
        );
      });
      module.entries = entries;
      setDetailResults(module);
    }
  };

  const formatoHora = new Intl.DateTimeFormat("es-ES", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "America/Santiago",
  });

  const handleDate = (date) => {
    let hora = formatoHora.format(new Date(date));
    let fecha_us = date.split("T")[0].split("-");
    let fecha_es = fecha_us[2] + "/" + fecha_us[1] + "/" + fecha_us[0];
    let fecha_final = fecha_es + " " + hora;
    return fecha_final;
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: "0",
          right: "0",
          height: "75px",
          backgroundColor: "rgb(52, 152, 219)",
          boxShadow: "rgb(0 0 0 / 28%) 0px 4px 8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          zIndex: "10",
          padding: "0 2rem",
        }}
      >
        <InatransLogo />
        <div style={{ marginLeft: "auto" }}>
          <Chip
            label={decodeURIComponent(config.course_name)}
            variant="filled"
            sx={{
              color: "#fff",
              marginLeft: 1,
              fontSize: 18,
              fontWeight: 500,
            }}
          />
        </div>
        <CSVLink data={rowsCSV}>
          <DownloadIcon sx={{ color: "#fff", marginLeft: 2 }} />
        </CSVLink>
      </div>
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          padding: "0 8rem",
        }}
      >
        {loading ? (
          <div>
            <Load />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "rgb(10, 25, 41)",
                  width: "80vw",
                  position: "absolute",
                  top: 80,
                  marginTop: "1rem",
                  marginBottom: "4rem",
                }}
                component={Paper}
              >
                <Table sx={{ minWidth: 600, }} aria-label="simple table">
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: "0",
                      left: "0",
                      background: "#343a40",
                      zIndex: "2",
                    }}
                  >
                    <TableRow>
                      <TableCell align="center" sx={{ color: "#fff" }}>
                        Nombre usuario
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#fff" }}>
                        Nombre del modulo
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#fff" }}>
                        Total Preguntas
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#fff" }}>
                        Correctas
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#fff" }}>
                        Incorrectas
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#fff" }}>
                        Resultado
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#fff" }}>
                        Fecha
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#fff" }}>
                        Detalle
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {rows &&
                      rows.map((module, idx) => (
                        <>
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              borderColor: "rgba(255, 255, 255, 0.12)",
                            }}
                          >
                            <TableCell sx={{ color: "#fff", borderColor: "rgba(255, 255, 255, 0.4)", }} align="center">
                              {decodeURIComponent(module.user_name)}
                            </TableCell>
                            <TableCell sx={{ color: "#fff",borderColor: "rgba(255, 255, 255, 0.4)" }} align="center">
                              {MODULES[module.module_results.module_name]}
                            </TableCell>
                            <TableCell sx={{ color: "#fff",borderColor: "rgba(255, 255, 255, 0.4)" }} align="center">
                              {module.module_results.totalanswers}
                            </TableCell>
                            <TableCell sx={{ color: "#fff",borderColor: "rgba(255, 255, 255, 0.4)" }} align="center">
                              {module.module_results.correct}
                            </TableCell>
                            <TableCell sx={{ color: "#fff",borderColor: "rgba(255, 255, 255, 0.4)" }} align="center">
                              {module.module_results.incorrect}
                            </TableCell>
                            <TableCell sx={{ color: "#fff",borderColor: "rgba(255, 255, 255, 0.4)" }} align="center">
                              {module.module_results.results}
                            </TableCell>
                            <TableCell sx={{ color: "#fff",borderColor: "rgba(255, 255, 255, 0.4)" }} align="center">
                              {module.update_date &&
                                handleDate(module.update_date)}
                            </TableCell>
                            <TableCell sx={{ color: "#fff",borderColor: "rgba(255, 255, 255, 0.4)" }} align="center">
                              <IconButton
                                sx={{
                                  color: "#fff",
                                  zIndex: "1",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDetail(module)}
                                aria-label=""
                              >
                                <Chip
                                  icon={
                                    <Visibility
                                      sx={{
                                        color: "#ccc",
                                        cursor: "pointer",
                                      }}
                                    />
                                  }
                                  label="Ver"
                                  variant="filled"
                                  sx={{
                                    color: "#fff",
                                    backgroundColor: "rgb(52, 152, 219)",
                                    cursor: "pointer",
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
        {/*         {!loading && (
          <Box sx={{ width: "20%", margin: "1rem 0" }}>
            <CSVLink data={rowsCSV}>
              <Fab variant="extended">
                <PrintIcon sx={{ mr: 1 }} />
                Descargar todos
              </Fab>
            </CSVLink>
          </Box>
        )} */}
      </div>

      <>
        <Dialog
          open={detail}
          maxWidth={"md"}
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: "#17181a",
              boxShadow: "none",
              paddingTop: "75px",
            },
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "0",
              right: "0",
              height: "75px",
              backgroundColor: "rgb(52, 152, 219)",
              boxShadow: "rgb(0 0 0 / 28%) 0px 4px 8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              zIndex: "10",
              padding: "0 1rem",
              gap: "22%",
            }}
          >
            <InatransLogo size={"sm"} />
            <div>
              <p style={{ fontSize: 20, fontWeight: 300 }}>
                Detalle {decodeURIComponent(detailResults?.user_name)}
              </p>
            </div>
            <CloseIcon
              sx={{ color: "#fff", cursor: "pointer", marginLeft: "auto" }}
              onClick={() => openDetail(false)}
            />
          </div>
          <DialogTitle className="text-white" sx={{ fontWeight: 400 }}>
            {" "}
            {MODULES[detailResults?.module_results?.module_name]}
          </DialogTitle>
          <DialogContent>
            {
              detailResults &&
                detailResults.module_results.module_name === "ina0" &&
                detailResults.entries.map((entry) => {
                  return (
                    <div
                      style={{ borderBottom: "1px solid #fff", marginTop: 75 }}
                    >
                      <p>Pregunta: {entry[0]}</p>;
                      <p>Respuesta: {JSON.parse(entry[1].split(";")[0])}</p>;
                      <p>
                        Correcta:{" "}
                        {entry[1].split(";")[1] === "true" ? "Si" : "No"}
                      </p>
                      ;
                    </div>
                  );
                }) /* (
                  <p>{JSON.stringify(detailResults.entries[0])}</p>
                ) */

              /*               (
                <DialogContentText className="text-white">
                  {JSON.stringify(detailResults.results)}
                </DialogContentText>
              ) */
            }

            {detailResults &&
              detailResults.module_results.module_name === "ina1" &&
              detailResults.module_results.results_detail.map((data) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                    /*                     style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }} */
                  >
                    <DialogContentText className="text-white flex-col">
                      <p>
                        Respuesta: {data.value
                          ? data.value == 1
                            ? "A"
                            : data.value == 2
                              ? "B"
                              : data.value == 3 && "C"
                          : ""}
                      </p>
                      <p>
                        Correcta: {data.correct ? "Si" : "No"}
                      </p>
                    </DialogContentText>
                    
                    <img
                      style={{ width: 300 }}
                      alt="..."
                      src={`https://tipddyassets.fra1.cdn.digitaloceanspaces.com/inatrans/optimized/idet-riesgos-updated-${data.key}.jpg`}
                    />
                  </div>
                );
              })}

            {detailResults &&
              detailResults.module_results.module_name === "ina2" &&
              detailResults.results.map((data) => {
                return (
                  <div
                    style={{
                      marginTop: 50,
                      borderBottom: "1px solid #fff",
                      paddingBottom: 25,
                    }}
                  >
                    <p style={{ fontSize: 18 }}>
                      Pregunta: {psycho_quiz[data.idx - 1].description}
                    </p>
                    <p style={{ fontSize: 18 }}>
                      Respuesta: {data.value === 1 ? "Si" : "No"}
                    </p>
                  </div>
                );
              })}

{detailResults &&
              detailResults.module_results.module_name === "ina4" &&
              detailResults.results.map((data) => {
                return (
                  <div
                    style={{
                      marginTop: 50,
                      borderBottom: "1px solid #fff",
                      paddingBottom: 25,
                    }}
                  >
                    <p style={{ fontSize: 18 }}>
                      Pregunta: {data.pregunta}
                    </p>
                    <p style={{ fontSize: 18 }}>
                      Respuesta: {data.respuesta === "si" ? "Si" : data.respuesta === "no" ? "No" : ''}
                    </p>
                  </div>
                );
              })}
          </DialogContent>
        </Dialog>
      </>
    </>
  );
}

export { Admin };

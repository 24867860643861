import axios from "axios";

const url = "https://tipconsola.iapp.cl/api/certified/tracking";
const headers = {
  "Content-Type": "application/json",
};
/* const parse = (str) => JSON.parse(JSON.parse(str)); */
export async function fetchTracking(config) {
  console.log(config);
  try {
    const { data } = await axios.post(
      url,
      {
        client: config.client,
        provider: config.provider,
        course_id: config.course_id,
        user_id: config.user_id,
        /*         url_cmid: config.url_cmid, */
      },
      { headers }
    );
    console.log("data fetchTrancking", data);
    let attempts = data.filter((m) => m.url_name === config.url_name);
    console.log("intentos segun servicio: ", attempts.length);
    return attempts.length;
  } catch (err) {
    console.log(err);
  }
}

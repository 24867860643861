import { Routes, Route } from "react-router-dom";
import { Layout } from "../components/layout";
import { Unauthorized } from "../views/401";
import { PrivateRoute } from "./private.route";

import { routes } from "./routes";

const CustomRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="*" element={<Unauthorized />} />
        {routes.map((route, idx) => {
          const Element = route.isPrivate ? (
            <PrivateRoute>
              <route.element />
            </PrivateRoute>
          ) : (
            <route.element />
          );

          return <Route path={route.path} element={Element} key={idx} />;
        })}
      </Route>
    </Routes>
  );
};

export default CustomRoutes;

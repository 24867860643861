export class Cookies {
  constructor(adapter){
    this.adapter = adapter
  }

  setCookiesConfig(){
    console.log(this.adapter.cookies.config)
    switch (this.adapter.cookies.config){
      case 'inatrans':
        this.adapter.inatrans(this.adapter.cookies)
        break;
      case 'admin':
         this.adapter.inatransAdmin(this.adapter.cookies)
         break;
      case 'modulelist':
        this.adapter.inatransModuleList(this.adapter.cookies)
        break;
      default:
        this.adapter.iframe(this.adapter.cookies);
        break
    }
  }
}
import React from "react";
import "./styles.css";
import QRCode from "qrcode";
/* import jsPDF from "jspdf";
import html2canvas from "html2canvas"; */
import html2pdf from "html2pdf.js";
import { useAuth } from "../../provider/auth";
import { REACT_APP_URL } from "../../utils/state";

const PDF = () => {
  const [codeQR, setCodeQR] = React.useState();
  const { auth } = useAuth();

  const generateQR = async (text) => {
    try {
      let imgData = await QRCode.toDataURL(text);
      setCodeQR(imgData);
    } catch (err) {
      console.error(err);
    }
  };

  /*   const printPDF = () => {
    const contentToPrint = document.getElementById("certificate");
    html2canvas(contentToPrint).then((canvas) => {
      let imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape", "cm", [34.3, 20.4]);
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("certificado.pdf");
    });
  }; */

  const printPDF = () => {
    const contentToPrint = document.getElementById("certificate");
    const contentHeight = contentToPrint.offsetHeight;
    html2pdf()
      .set({
        orientation: "landscape",
        filename: "certificado.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { height: contentHeight },
        jsPDF: { unit: "cm", orientation: "landscape" },
      })
      .from(contentToPrint)
      .save();
  };

  React.useEffect(() => {
    let c = auth.cookies;
    console.log(JSON.parse(c.isApproved));
    generateQR(
      `${REACT_APP_URL.PROD}?course_id=${c.course_id}&course_name=${c.course_name}&user_id=${c.user_id}&user_name=${c.user_name}&user_email=${c.user_email}&url_cmid=${c.url_cmid}&url_name=certificate&config=${c.config}&isApproved=${c.isApproved}`
    );
    console.log(auth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container father">
      <div className="container fil" id="certificate">
        <div className="container con">
          <div className="row align-item-center mt-2">
            <div className="col-md-6 log">
              <img
                src={require("../../assets/logo.jpg")}
                className="logo-inatrans"
                alt="logo inatrans"
              />
            </div>
            <div className="col-md-6 d-flex">
              {codeQR && <img src={codeQR} className="qr" alt="código QR" />}
            </div>
          </div>

          <div className="col-md-12">
            <p className="titulo">CERTIFICAN QUE</p>
          </div>
          <div className="col-md-12">
            <p className="titulo-baj">{auth.cookies.user_name}</p>{" "}
            {/* cambiar cuando se tenga fullname en la cookie*/}
          </div>
          <div className="col-md-12">
            <p className="titulo-rut"></p>{" "}
            {/* cambiar cuando se tenga rut en la cookie*/}
          </div>
          <div className="col-md-12">
            <p className="titulo-text">
              Ha{" "}
              {JSON.parse(auth.cookies.isApproved)
                ? "aprobado satisfactoriamente"
                : "reprobado"}{" "}
              los Modulos del Curso{" "}
              <span className="titulo-text2">
                <br />
                Prediccón de Riesgos, Identificación de Riesgos y Evaluacion
                Psicológica
              </span>
              <br />
              Realizado en modalidad presencial por instructor especializado de
              Inatrans,
              <br />
              con duración de 16 horas cronológicas.
            </p>
          </div>
        </div>

        <div className="container esp"></div>

        <div className="container con">
          <div className="row">
            <div className="col-md-4">
              <p className="titulo-cargo">Cargo Personal</p>
            </div>
            <div className="col-md-4">
              <p className="titulo-cargo">Cargo Personal</p>
            </div>
            <div className="col-md-4">
              <p className="titulo-cargo">Cargo Personal</p>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary print-button"
        onClick={printPDF}
      >
        Imprimir PDF
      </button>
    </div>
  );
};

export default PDF;

import React from "react";
import "./index.css";
import { useAuth } from "./provider/auth";
import { ModalProvider } from "./provider/modal";
import { ConfigProvider } from "./provider/config";

import CustomRoutes from "./routes";

export function App() {
  const { auth, authActions } = useAuth();

  if (window.location.search) {
    localStorage.clear();
    authActions.setCookie();
  }

  if (auth.cookies.brand) {
    authActions.setBranding(auth.cookies.brand);
  }

  return (
    <ModalProvider>
      <ConfigProvider>
        <CustomRoutes />
      </ConfigProvider>
    </ModalProvider>
  );
}

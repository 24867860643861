import notAuth from "../../401.svg";
import React from "react";

export function Unauthorized() {
  return (
    <main style={{ backgroundColor: "#000" }}>
      <p style={{ textAlign: "center", color: "#fff" }}> No authorizado </p>
      <img alt="404" src={notAuth} width="10%" />
    </main>
  );
}

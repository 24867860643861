import  ReactDOM  from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from './provider/app'
import { AuthProvider } from './provider/auth'
import { SnackbarProvider } from 'notistack'
import { App } from './App'




ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        >
        <AppProvider>
      <AuthProvider>
          <App/>
      </AuthProvider>
        </AppProvider>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById('root')
)





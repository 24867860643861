import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../provider/auth";
import { useApp } from "../../provider/app";
import { useMobile } from "../../utils";
import { LoaderV2 } from "../loader";
import { Navigation } from "../navigation";
import { Modal } from "../modal";

export function Layout() {
  const { auth, authActions } = useAuth();
  const { app, appActions } = useApp();
  const [isMobile] = useMobile();

  useEffect(() => {
    if (!auth.cookies) {
      authActions.getCookies("config");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const outlet_css = {
    position: "absolute",
    top: auth.config === "moodle" ? "4rem" : "0",
    left: 0 /* isMobile ? '0' : '5rem' */,
    bottom: "0",
    right: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "-1000",
    backgroundColor: "#334155",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflowY: "scroll",
  };

  return (
    <div>
      {app.loading ? (
        <LoaderV2 app={app} appActions={appActions} />
      ) : (
        <>
          {auth.config === "inatrans" ? (
            <Navigation isMobile={isMobile} auth={auth} />
          ) : (
            ""
          )}
          <div style={outlet_css}>
            <Outlet />
          </div>
          <Modal />
        </>
      )}
    </div>
  );
}

import React from "react";
import { VideoWrapper } from "../../components/video-wrapper";
import { VideoPlayer } from "../../components/video-player";
import { useAuth } from "../../provider/auth";
import { useEffect } from "react";
import { InatransPlayerCompleted } from "../module-completed";
import { fetchTracking } from "../../services/index";
import axios from "axios";

export function Console() {
  const [isTracked, setIsTracked] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [startDate] = React.useState(new Date().toLocaleDateString());
  const { auth, config, authActions } = useAuth();
  const [finishedForNextActivity, setFinishedForNextActivity] = React.useState(false)

  //  useEffect(() => {
  //   if (auth.user.user_id /* && !track */) {
  //     /* track = true; */
  //     authActions.getTracking(() => {
  //       console.log("--");
  //     });
  //   }
  //   setIsTracked(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (auth.user.user_id && auth.cookies.intentos !== "test") { 
      /* track = true; */
      fetchTracking(config).then((data) => {
        if (data >= auth.cookies.intentos) {
          setCompleted(true);
        }
        /* setCompleted(data); */
      });
    }
    setIsTracked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // async function fetchTracking() {
  //   const trackingURL = "https://tipconsola.iapp.cl/api/exist/tracking";
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   try {
  //     const { data } = await axios.post(
  //       trackingURL,
  //       {
  //         client: config.client,
  //         provider: config.provider,
  //         course_id: config.course_id,
  //         user_id: config.user_id,
  //         url_cmid: config.url_cmid,
  //       },
  //       { headers }
  //     );
  //     console.log(typeof data);
  //     console.log(data);
  //     if (typeof data !== "string" && data.length > 0) {
  //       console.log({ data });
  //       setCompleted(true);
  //       return;
  //     }
  //     setCompleted(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  return (
    <>
      {completed || finishedForNextActivity ? (
        <InatransPlayerCompleted url_name="inatrans-identificacion-de-riesgos"/>
      ) : (
        <InatransPlayer
          auth={auth}
          startDate={startDate}
          isTracked={isTracked}
          setFinishedForNextActivity={setFinishedForNextActivity}
        />
      )}
    </>
  );
}

export const InatransPlayer = ({ auth, startDate, isTracked,setFinishedForNextActivity }) => {
  return (
    <VideoWrapper>
      {isTracked && <VideoPlayer config={auth.config} startDate={startDate} setFinishedForNextActivity={setFinishedForNextActivity} />}
      {/* <VideoPlayer config={auth.config} startDate={startDate} /> */}
    </VideoWrapper>
  );
};

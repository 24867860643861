import React from "react";
import { useModal } from "../../provider/modal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { useAuth } from "../../provider/auth";
import { Button } from "@mui/material";

export const Modal =
  ({url_name=''}/* { onModalClose, onUserResponse, user, useSnackbar } */) => {
    const { modal_state } = useModal();
    const { auth } = useAuth();
    const { modal_type } = modal_state;
    const modal_res = modal_type === "answer" ? true : false;

    return (
      <Dialog
        open={modal_res}
        fullScreen
        //onClose={onModalClose}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none",
          },
        }}
      >
        <Dialog
          open={modal_res}
          PaperProps={{
            style: {
              backgroundColor: "#17181a",
              boxShadow: "none",
              padding: "1rem",
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="text-white" id="alert-dialog-title">
            {"Evaluación finalizada"}
          </DialogTitle>
          <DialogActions style={{ color: "#fff" }}>
            Para ingresar a otros modulos, regresa a campus virtual:
            <a
              style={{ textDecoration: "underline", marginLeft: "5px" }}
              href={`http://elearning.inatrans.cl/course/view.php?id=${auth.cookies.course_id}`}
            >
              {" "}
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>
            </a>
          </DialogActions>
          <DialogActions style={{ color: "#fff" }}>

            {url_name !== "inatrans-actitud-y-conducta" && 
            <p className="lead mt-3">
            Para continuar al siguiente módulo:{" "}
            <a
              style={{ textDecoration: "underline",marginLeft: "5px" }}
              href={`https://tipconsola.inatrans.cl/?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1414141413&url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=test`}
              >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>}
              </DialogActions>
              <DialogActions style={{ color: "#fff" }}>

            { 
            <p className="lead mt-3">
            Para regresar a la lista de actividades:{" "}
            <a
              style={{ textDecoration: "underline",marginLeft: "5px" }}
              href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules`}
              >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>}
              </DialogActions>
        </Dialog>
      </Dialog>
    );
  };

import { URL_NAMES } from "./state";

export const useMobile = () => {
  const isMobile = window.innerWidth < 900;
  return [isMobile];
};

/**
 *
 * @param {correctAnswers} as  cq
 * @param {moduleName} as  name
 */
export const defineApproved = ({ ca = "", name, final = [] }) => {
  // console.log({ final });
  switch (name) {
    case URL_NAMES.MODULO_I:
      if (ca >= 27) return "Cumple";

      if (ca < 27) return "No Cumple";

    case URL_NAMES.MODULO_II:
      if (ca >= 18) return "Cumple";

      if (ca < 18) return "No Cumple";
    case URL_NAMES.MODULO_III:
      if (ca === 5) return "Cumple";

      if (ca === 4) return "Cumple Medianamente";

      if (ca < 4) return "No Cumple";
    case URL_NAMES.MODULO_IV:
      return "Cumple";
    case URL_NAMES.CERTIFICATE:
      if (final.length < 3) return "Reprobado";
      if (final.includes("No Cumple")) return "Reprobado";

      let med = final.filter((x) => x === "Cumple Medianamente").length;

      if (med >= 2) return "Reprobado";
      else return "Aprobado";
  }
};

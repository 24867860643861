import React from "react";
import logo_tipddy from "../../assets/logo-tipddy.png";

export const Home = () => {
  return (
    <body
      className="d-flex h-100 text-center text-white bg-dark"
      style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}
    >
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
         <main className="px-3">
          <h1>Consola Interactiva </h1>
          <p className="lead w-25 mt-3">
            Para ingresar a un modulo debes ir al campus virtual de tu
            organizacion
          </p>
        </main>

        <footer
          className="mt-auto text-white-50 d-flex "
          style={{ justifyContent: "center" }}
        >
          <img src={logo_tipddy} alt="..." className="img-rounded" />
          <p>Copyright Powered by Tipddy</p>
        </footer>
      </div>
    </body>
  );
};

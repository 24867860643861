import React from "react";

const useTimer = (initialState = 0) => {
  const [elapsedTime, setElapsedTime] = React.useState(initialState);
  const [isRunning, setIsRunning] = React.useState(false);
  const countRef = React.useRef(null);

  const intervalRef = React.useRef(null);

  const start = () => {
    if (!isRunning) {
      console.log("start timer");
      const startTime = Date.now() - elapsedTime * 1000;
      intervalRef.current = setInterval(() => {
        /* console.log(
          "question timer " + Math.floor((Date.now() - startTime) / 1000)
        ); */
        setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
      setIsRunning(true);
    }
  };

  const stop = () => {
    console.log("pause timer");
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setIsRunning(false);
  };

  const reset = () => {
    console.log("reset timer");
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setIsRunning(false);
    setElapsedTime(0);
  };
  /*   React.useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
 */
  return { elapsedTime, isRunning, start, stop, reset };
};

export default useTimer;

import React from "react"

export const MODAL_STATE = {
    modal: true,
    modal_title: 'Introducción a la actividad',
    modal_type: 'intro',
    answers: [],
    /* answers: ['¿Cual es tu nombre?', '¿Cual es tu pais?', '¿Cual es tu ciudad?', '¿Cual es tu presente?'], */
    user_answers: {...localStorage},
    correct_answer: null
  }
  
export const MODAL_REDUCER = (state, action) => {
  const { payload } = action
  switch(action.type){
      case MODAL_ACTION_TYPE.display:
          return {
              ...state,
              modal: true,
              modal_title: payload.modal_title,
              modal_type: payload.modal_type,
              answers: payload.answers,
              correct_answer: payload.correct_answer
          }
      case MODAL_ACTION_TYPE.answer:
          return {
              ...state,
              modal: true,
              answers: [],
              modal_title: payload.modal_title,
              modal_type: payload.modal_type,
              user_answers: payload.user_answers
          }
      case 'final':
          return {
              ...state,
              modal: false,
              answers: [],
              modal_title: '',
              modal_type: 'final',
              user_answers: []
          }
      case MODAL_ACTION_TYPE.none:
          return {
              ...state,
              modal: false,
          }
      default:
          return MODAL_STATE
  }
}

export const MODAL_ACTION_TYPE = {
  display: 'display',
  none: 'none',
  answer: 'answer'
}

export const ModalContext = React.createContext()

export const ModalProvider = ({children}) => {
    const [modal_state, modal_dispatch] = React.useReducer(MODAL_REDUCER, MODAL_STATE)

    let value = { modal_state, modal_dispatch }
    return(
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    )
}

export const useModal = () => {
  return React.useContext(ModalContext)
}
import { INATRANS } from "../../utils/state"

export class InatransNavigation {
  constructor(cookies){
    this.cookies = cookies
  }
  // navigate(){
  //   if(window.location.pathname === '/' && this.cookies['url_name'] === INATRANS.RISK_INTERCEPTOR){
  //     window.location.replace(`/${INATRANS.RISK_INTERCEPTOR}`)
  //   }
  //   if(window.location.pathname === '/' && this.cookies['url_name'] === INATRANS.PSYCHOLOGY_QUIZ){
  //     window.location.replace(`/${INATRANS.PSYCHOLOGY_QUIZ}`)
  //   }
  //   if(window.location.pathname === '/' && this.cookies['url_name'] === 'quest'){
  //     window.location.replace(`/protected`)
  //   }
  // }
  navigate(){
    console.log(this.cookies['url_name'] )
    if(window.location.pathname !== `/${INATRANS.RISK_INTERCEPTOR}` && this.cookies['url_name'] === INATRANS.RISK_INTERCEPTOR){
      window.location.replace(`/${INATRANS.RISK_INTERCEPTOR}`)
    }
    if(window.location.pathname !== `/${INATRANS.PSYCHOLOGY_QUIZ}` && this.cookies['url_name'] === INATRANS.PSYCHOLOGY_QUIZ){
      window.location.replace(`/${INATRANS.PSYCHOLOGY_QUIZ}`)
    }
    if(window.location.pathname !== `/modules` && this.cookies['url_name'] === 'modules'){
      window.location.replace(`/modules`)
    }
    if(window.location.pathname !== `/inatrans-actitud-y-conducta` && this.cookies['url_name'] === 'inatrans-actitud-y-conducta'){
      window.location.replace(`/inatrans-actitud-y-conducta`)
    }
    if(window.location.pathname !== '/protected' && this.cookies['url_name'] === 'quest'){
      window.location.replace(`/protected`)
    }
    if(window.location.pathname !== `/${INATRANS.CERTIFICATE}` && this.cookies['url_name'] === INATRANS.CERTIFICATE){
      window.location.replace(`/${INATRANS.CERTIFICATE}`)
    }
    if(window.location.pathname !== `/${INATRANS.PDF}` && this.cookies['url_name'] === INATRANS.PDF){
      window.location.replace(`/${INATRANS.PDF}`)
    }
  }
}